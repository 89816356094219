import Airport from './Airport';
import Airline from './Airline';
import Fwb from './Fwb';

const Components = {
    Airport : Airport,
    Airline : Airline,
    Fwb
}

export default Components;