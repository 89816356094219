import List from './List';
import Form from './Form';
import OperatorAirportAmd from './OperatorAirportAmd';

const Components = {
	List,
	Form,
	OperatorAirportAmd
}

export default Components;